import { format, differenceInDays, formatISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { PREORDER_TIMEZONE } from "../constants/Common";

// selectedVariantOptions would be an object, something like: { Letter: 'A'}
export function getGalleryImages(product, selectedVariantOptions) {
  if (product?.images) {
    const variant = product.variants.find((v) =>
      v.selectedOptions.every((o) => o.value === selectedVariantOptions[o.name])
    );
    const variantImages = new Set();
    product.variants.forEach((v) => variantImages.add(v.image?.url));
    const images = variant?.image ? [variant.image] : [];
    for (const img of product.images) {
      if (!variantImages.has(img.url)) {
        images.push(img);
      }
    }
    return images;
  }
  return [];
}

export function getShipByDate(date, returnFormat = "MMMM do") {
  const adjustedDate = date.includes("T") ? date.split("T")[0] : date; // Remove the time portion if present
  return format(utcToZonedTime(`${adjustedDate}T23:59:59Z`, PREORDER_TIMEZONE), returnFormat);
}

export function getDaysUntilShipping(shipsByDate) {
  return differenceInDays(
    utcToZonedTime(
      `${formatISO(shipsByDate, { representation: "date" })}T23:59:59Z`,
      PREORDER_TIMEZONE
    ),
    new Date()
  );
}
