import { GatsbyImage } from "gatsby-plugin-image";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import RichTextContentful from "../RichTextContentful";
import { ALLink } from "../ALComponents";
import ArrowIcon from "../icons/svgs/arrow.svg";
import * as articleRecommendationStyles from "./articleRecommendation.module.scss";

function ArticleRecommendation({ article }) {
  return (
    <ALLink className={`${articleRecommendationStyles.article}`} to={`/blog/${article.handle}`}>
      {article.image.altText !== "empty" && (
        <>
          <div
            className={`${articleRecommendationStyles.article_image} ${articleRecommendationStyles.article_imageMobile}`}
          >
            <GatsbyImage
              fetchPriority="high"
              image={article.image.mobileImage.gatsbyImageData}
              alt={article.image.altText}
              className={articleRecommendationStyles.article_image__gatsby}
            />
          </div>
          <div
            className={`${articleRecommendationStyles.article_image} ${articleRecommendationStyles.article_imageDesktop}`}
          >
            <GatsbyImage
              fetchPriority="high"
              image={article.image.desktopImage.gatsbyImageData}
              alt={article.image.altText}
              className={articleRecommendationStyles.article_image__gatsby}
            />
          </div>
        </>
      )}

      <p className={`h4 h4--bold ${articleRecommendationStyles.article_title}`}>{article.title}</p>
      <div className={articleRecommendationStyles.article_summary}>
        <RichTextContentful input={article.summary?.raw} />
      </div>
      <div className={articleRecommendationStyles.article_link}>
        <ArrowIcon />
        <p>Learn more</p>
      </div>
    </ALLink>
  );
}

export default withALErrorBoundary({
  name: "ArticlePreview",
  priority: "P1",
})(ArticleRecommendation);
