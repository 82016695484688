import { useRef, useEffect, useMemo, useContext } from "react";

import { Link } from "gatsby";
import { useLocation } from "@reach/router";

import { LuxeContext } from "../context/LuxeProvider";
import useDeviceDetect from "../hooks/useDeviceDetect";
import { useSticky } from "../hooks/useSticky";

import * as Styles from "./alRewardsNavigation/styles.module.scss";

function ALRewardsNavigation() {
  const location = useLocation();
  const { luxe } = useContext(LuxeContext);
  const { isMobile } = useDeviceDetect();
  const stickyNav = useSticky(false);
  const activeRef = useRef(null);

  const { luxeEdit, isLuxeActive } = luxe;
  const showLuxeEditNav = !!(
    isLuxeActive &&
    luxeEdit.title !== "null" &&
    luxeEdit?.collection?.handle
  );

  const navItems = useMemo(
    () => [
      {
        id: "account",
        title: "Account Details",
        link: "/account",
      },
      ...(isLuxeActive ? [{ id: "luxe", title: "AL Luxe", link: "/ana-luisa-luxe" }] : []),
      ...(showLuxeEditNav
        ? [
            {
              id: "luxe",
              title: "The Luxe Edit",
              link: `/collections/${luxeEdit.collection?.handle}`,
            },
          ]
        : []),
      {
        id: "order-history",
        title: "Order History",
        link: "/order-history",
      },
      {
        id: "wishlist",
        title: "Wishlist",
        link: "/pages/wishlist",
      },
    ],
    [luxeEdit, isLuxeActive, showLuxeEditNav]
  );

  useEffect(() => {
    if (isMobile && activeRef.current) {
      activeRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [isMobile]);

  return (
    <div className={Styles.linkContainer} style={isMobile ? stickyNav : {}}>
      {navItems.map((navItem) => {
        const activeLink = location.pathname.replace(/\/$/, "") === navItem.link;

        return (
          <Link
            key={navItem.title}
            to={navItem.link}
            className={`${Styles.link} ${activeLink ? Styles.active : ""} ${
              navItem.id === "luxe" ? Styles.luxe : ""
            }`}
            ref={activeLink ? activeRef : null}
          >
            {activeLink ? <h1>{navItem.title}</h1> : navItem.title}
          </Link>
        );
      })}
    </div>
  );
}

export default ALRewardsNavigation;
