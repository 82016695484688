import { GatsbyImage } from "gatsby-plugin-image";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import RichTextContentful from "../RichTextContentful";
import { ALButton, ALLink } from "../ALComponents";

import * as articlePreviewStyles from "./articlePreview.module.scss";

function ArticlePreview({ article, isPin = false }) {
  return (
    <ALLink
      className={`${articlePreviewStyles.article} ${
        isPin ? articlePreviewStyles.articleIsActive : ""
      }`}
      to={`/blog/${article.handle}`}
    >
      {article.image.altText !== "empty" && (
        <>
          <div
            className={`${articlePreviewStyles.article_image} ${articlePreviewStyles.article_imageMobile}`}
          >
            <GatsbyImage
              fetchPriority="high"
              image={article.image.mobileImage.gatsbyImageData}
              alt={article.image.altText}
              className={articlePreviewStyles.article_image__gatsby}
            />
          </div>
          <div
            className={`${articlePreviewStyles.article_image} ${articlePreviewStyles.article_imageDesktop}`}
          >
            <GatsbyImage
              fetchPriority="high"
              image={article.image.desktopImage.gatsbyImageData}
              alt={article.image.altText}
              className={articlePreviewStyles.article_image__gatsby}
            />
          </div>
        </>
      )}

      <p className={`h2 ${articlePreviewStyles.article_title}`}>{article.title}</p>
      <RichTextContentful input={article.summary?.raw} />
      <ALButton
        className={articlePreviewStyles.article_read}
        variant={isPin ? "secondary" : "primary"}
      >
        READ MORE
      </ALButton>
    </ALLink>
  );
}

export default withALErrorBoundary({
  name: "ArticlePreview",
  priority: "P1",
})(ArticlePreview);
