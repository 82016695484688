import { Link } from "../../components/al_components/ALLink";
import { useCurrentCountryCode } from "../../hooks/usePrices";
import { getShopifyStoreByCountry } from "../../../locale-shopifies";
import SEO from "../../components/seo";
import * as siteMapStyle from "./sitemap.module.scss";

function Sitemap({ pageContext }) {
  const countryCode = useCurrentCountryCode();

  return (
    <div className={siteMapStyle.container}>
      <SEO
        title="Sitemap"
        description="Looking for exquisite jewelry pieces that reflect your unique style? Explore Ana Luisa's sitemap for a curated collection of handcrafted, high-quality jewelry. From delicate necklaces to statement rings, discover timeless designs that will elevate your look. Shop now at analuisa.com and indulge in the art of self-expression."
      />
      <h1 className={`${siteMapStyle.container_title} al_h1`}>Sitemap</h1>
      <div className={siteMapStyle.container_links}>
        <Link className={siteMapStyle.container_link} to="/account/login">
          Login
        </Link>
        <Link className={siteMapStyle.container_link} to="/account">
          Account
        </Link>
        <Link className={siteMapStyle.container_link} to="/account/register">
          Create an account
        </Link>
        <a
          className={siteMapStyle.container_link}
          href={getShopifyStoreByCountry(countryCode)?.knowledgeBase}
          target="_blank"
          rel="noreferrer"
        >
          FAQ (Frequently Asked Questions)
        </a>
        {pageContext?.data?.collections.map((e) => (
          <Link
            key={`sitemap_link_${e.id}`}
            to={`/collections/${e.handle}`}
            className={siteMapStyle.container_link}
          >
            {e.title.toLowerCase()}
          </Link>
        ))}
        {pageContext?.data?.articles.map((article) => (
          <Link
            key={`sitemap_link_${article.id}`}
            to={`/blog/${article.handle}/`}
            className={siteMapStyle.container_link}
          >
            {article.title}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Sitemap;
