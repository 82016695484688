import { useMemo, useState, useEffect } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Masonry from "react-masonry-css";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { getAllUrlParams, updateURLParam } from "../../context/helpers";

import { Link } from "../../components/al_components/ALLink";
import Breadcrumb from "../../components/Breadcrumb";
import ALCustomLandingPage from "../../components/al_components/ALCustomLandingPage";
import ArticlePreview from "../../components/blog/ArticlePreview";
import StaticPagination from "../../components/CollectionPage/StaticPagination";

import * as blogHomePageStyles from "../../components/blog/styles.module.scss";

function BlogPage({ pageContext }) {
  const {
    blocks,
    filters: filtersMenu,
    image,
    subtitle,
    title,
    textOnImage,
  } = pageContext?.data || {};
  const { category, articles } = pageContext;
  const urlParams = useMemo(() => getAllUrlParams(), []);
  const nbProductsByRow = 2;
  const rowsPerPage = 2;

  const [page, setPage] = useState(() => parseInt(urlParams?.page, 10) || 1);

  useEffect(() => setPage(parseInt(urlParams?.page, 10) || 1), [urlParams]);

  const articlesToDisplay = useMemo(() => {
    if (!articles || articles.length === 0) return [];
    const itemsPerPage = nbProductsByRow * rowsPerPage;

    // Calculate start and end indices for the current page
    const startIndex = itemsPerPage * (page - 1);
    const endIndex = itemsPerPage * page;

    // Return the sliced array for the current page
    return articles.slice(startIndex, endIndex);
  }, [articles, page, nbProductsByRow, rowsPerPage]);

  const breadcrumb = [{ link: { link: "/blog" }, title: "Blog" }];
  if (category) {
    breadcrumb.push({ link: { link: `/blog/${category.toLowerCase()}` }, title: category });
  }

  return (
    <>
      <div className={blogHomePageStyles.breadcrumb}>
        <Breadcrumb levels={breadcrumb} />
      </div>

      <div className={blogHomePageStyles.container_image}>
        <GatsbyImage
          className={blogHomePageStyles.container__imageMobile}
          image={getImage(image.mobileImage.gatsbyImageData)}
          alt={image.altText}
        />
        <GatsbyImage
          className={blogHomePageStyles.container__imageDesktop}
          image={getImage(image.desktopImage.gatsbyImageData)}
          alt={image.altText}
        />
        <p className={blogHomePageStyles.container_image_text}>{textOnImage}</p>
      </div>

      {title && title !== "null" && <h1 className={blogHomePageStyles.container_title}>{title}</h1>}

      {subtitle && subtitle !== "null" && (
        <p className={blogHomePageStyles.container_subtitle}>{subtitle}</p>
      )}

      <div className={blogHomePageStyles.fitlers}>
        {/* TODO */}
        {filtersMenu.map((filter) => (
          <Link
            className={`text ${blogHomePageStyles.fitlers_filter}`}
            key={`filter_${filter.title.toLowerCase()}`}
            to={filter.link.link}
          >
            {filter.title}
          </Link>
        ))}
      </div>

      <div className={blogHomePageStyles.articles}>
        <Masonry
          breakpointCols={{
            default: 2,
            770: 1,
          }}
          className={`${blogHomePageStyles.articlesMasonryGrid}`}
          columnClassName={`${blogHomePageStyles.articlesMasonryGrid_column}`}
        >
          {articlesToDisplay.map((article, i) => (
            <ArticlePreview
              key={`article_${article.handle}_${i + 1}`}
              article={article}
              isPin={i === 0}
            />
          ))}
        </Masonry>
      </div>

      <div className={blogHomePageStyles.pagination}>
        {/* SEO ONLY HIDE CONTENT */}
        <StaticPagination
          className={blogHomePageStyles.pagination__seo}
          page={page}
          itemsPerPage={1}
          itemsCount={articles.length}
        />

        {/* DISPLAY TO USERS */}
        <StaticPagination
          className={blogHomePageStyles.pagination__display}
          page={page}
          itemsPerPage={1}
          itemsCount={articles.length}
          handlePageChange={(p) => {
            setPage(p);
            // Update page query param
            updateURLParam("page", p.toString());
          }}
        />
      </div>

      <ALCustomLandingPage data={{ blocks }} componentType="blog" />
    </>
  );
}

export default withALErrorBoundary({
  name: "BlogPage",
  priority: "P1",
})(BlogPage);
