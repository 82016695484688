import { useContext, useState, useEffect, useMemo, useRef, useCallback } from "react";
import { navigate, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import LazyLoad from "react-lazyload";
import loadable from "@loadable/component";

import { WishlistContext } from "../../context/wishlistProvider";
import { LoopReturnContext } from "../../context/LoopReturnProvider";
import { useTracking } from "../../context/Tracking";
import { useProductData2 } from "../../context/products";
import {
  isBrowser,
  gaCustomEvent,
  buildSEOProductObject,
  buildSEOProductGroupObject,
  populateProduct,
  edgesToArray,
} from "../../context/helpers";

import useDeviceDetect from "../../hooks/useDeviceDetect";
import {
  useCurrentCurrencyCode,
  useFillProductPrices,
  usePriceLocalInfo,
  usePriceInfo,
  useCurrentCountryCode,
} from "../../hooks/usePrices";

import { useExclusionTags } from "../../hooks/useExclusionTags";
import { useSticky } from "../../hooks/useSticky";
import useCheckInventory from "../../hooks/useCheckInventory";
import useRecommendation from "../../hooks/useRecommendation";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import useIsHydrationComplete from "../../hooks/useIsHydrationComplete";

import { useALError, withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { getGalleryImages } from "../../helpers/product";
import useGetProductsByCollection from "../../hooks/useGetProductsByCollection";

import { ActionType } from "../../constants/ActionType";
import { ComponentType } from "../../constants/ComponentType";
import { HandleType } from "../../constants/HandleType";
import { Country } from "../../constants/Country";
import { InventoryPolicies } from "../../constants/InventoryPolicies";

import SEO from "../../components/seo";
import ProductDetails from "../../components/ProductPage/Details/ProductDetails";
import Breadcrumb from "../../components/Breadcrumb";
import Gallery from "../../components/ProductPage/Gallery";
import { ALSkeleton, ALTag } from "../../components/ALComponents";
import AggregateOfferMeta from "../../components/ProductPage/Seo/AggregateOfferMeta";
import ProductSummary from "../../components/ProductPage/ProductSummary";
import DropHintPopup from "../../components/ProductPage/DropHintPopup";
import SaleBlock from "../../components/ProductPage/SaleBlock";
import ProductsInCollection from "../../components/ProductPage/ProductsInCollection";
import ALCustomLandingPage from "../../components/al_components/ALCustomLandingPage";

import "./product.scss";

const RecommendedProducts = loadable(() => import("../../components/RecommendedProducts"));
const Reviews = loadable(() => import("../../components/ProductPage/Reviews"));
const FloatingAddToCart = loadable(() => import("../../components/AddToCart/FloatingAddToCart"));
const GetInspired = loadable(() => import("../../components/ProductPage/GetInspired"));

function usePreviouslySeenProducts(productHandle) {
  const [previouslySeenProducts, setPreviouslySeenProducts] = useState(null);
  if (!isBrowser) {
    return null;
  }

  useEffect(() => {
    if (typeof window?.exponea?.lastViewed !== "undefined") {
      const lastViewed = window?.exponea?.lastViewed;
      const handles = lastViewed.reduce((red, r) => {
        const _p = r.split("/");
        const idxP = _p.findIndex((e) => e == "products");
        const _handle = _p[idxP + 1];
        if (_handle && _handle !== productHandle && !red.includes(_handle)) {
          red.push(_handle);
        }
        return red;
      }, []);

      setPreviouslySeenProducts(handles);
    }
  }, []);

  return previouslySeenProducts;
}

function DisplayRecommendedProducts({ data }) {
  const product = useFillProductPrices({ product: data.shopifyProduct, shouldUseDiscount: true });
  const previouslySeenProducts = usePreviouslySeenProducts(product?.handle);
  const [, boughtTogetherProducts, boughtTogetherId] = useRecommendation("PDP");
  const bestSellersProducts = useGetProductsByCollection("bestsellers");

  // Show Best Sellers when there's no data from Exponea
  if (
    typeof window?.exponea === "undefined" ||
    typeof window.exponea?.getRecommendation !== "function"
  ) {
    return (
      <RecommendedProducts
        componentType={ComponentType.PDP}
        handles={bestSellersProducts}
        collection="best-sellers"
        title="Best Sellers"
      />
    );
  }

  // Show Previously Seen when there are previously seen products
  if (previouslySeenProducts && previouslySeenProducts.length > 3) {
    return (
      <RecommendedProducts
        componentType={ComponentType.PDP}
        handles={previouslySeenProducts}
        title="Previously Seen"
        recommendationId="previously_seen"
      />
    );
  }

  // Show Bought Together for first time customers
  return (
    <RecommendedProducts
      componentType={ComponentType.PDP}
      handles={boughtTogetherProducts.map((product) => product.handle)}
      title="Bought together"
      recommendationId={boughtTogetherId}
    />
  );
}

function getVariantOptions(variant) {
  return variant.selectedOptions.reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {});
}

function ProductPage({ data, pageContext, location }) {
  const { throwReport, sendReport } = useALError();
  if (data?.allShopifyPrices?.edges && data?.allShopifyPrices?.edges.length === 0) {
    if (isBrowser) {
      sendReport(
        new Error("Empty Prices (most likely product published on Contentful, but not on Shopify"),
        { name: "ProductPage", priority: "P1" }
      );
      navigate("/404");
    }
    return null;
  }

  const topPosition = useSticky(false, "3rem");
  const { isMobile } = useDeviceDetect();
  const currency = useCurrentCurrencyCode();
  const isHydrationComplete = useIsHydrationComplete();
  const wishlistContext = useContext(WishlistContext);
  const { isLoopReturnEnabled } = useContext(LoopReturnContext);

  const countryCode = useCurrentCountryCode();

  const productPrice = data?.allShopifyPrices?.edges.find(
    (p) => p?.node?.country === countryCode
  )?.node;
  const product = useFillProductPrices({
    product: data.shopifyProduct,
    shouldUseDiscount: true,
    localPrice: productPrice,
  });
  const { trackViewProductPage, trackDropHintOpenPopup, trackDropHintClosePopup } = useTracking();
  const exclusionTags = useExclusionTags();

  const refReviewContainer = useRef(null);
  const variantsSelectorRef = useRef(null);

  const _product = useMemo(() => populateProduct(data), [data]);

  const [optionalProducts, setOptionalProducts] = useState([]);
  const [showDropHintPopup, setShowDropHintPopup] = useState(false);

  const [variantSelectedOptions, setVariantSelectedOptions] = useState(
    getVariantOptions(product.variants.find((v) => v.availableForSale) || product.variants[0])
  );
  const [userChanged, setUserChanged] = useState(false);

  const { variants: productVariantsInventory } = useCheckInventory(product?.handle);

  // Product alternatives
  const alternativesTags = data.alternativesProducts.edges.reduce(
    (red, p) => ({ ...red, [p?.node?.handle]: p?.node?.tags || [] }),
    {}
  );
  const alternativesHandles = data.alternativesProducts.edges.map((p) => p?.node?.handle);

  const alternativesPrices = usePriceInfo({
    handles: alternativesHandles,
    tags: alternativesTags,
    shouldFetchPrice: true,
  });

  // Complete the look
  const { data: completeTheLookProductsData } = useProductData2(
    _product?.contentful?.completeTheLook?.split(","),
    "long",
    countryCode
  );
  const completeTheLookProducts = useMemo(
    () =>
      completeTheLookProductsData.length > 0
        ? completeTheLookProductsData.filter((p) => p) // Should be removed and use a skeleton instead
        : completeTheLookProductsData,
    [completeTheLookProductsData]
  );

  const productBundleReference = _product?.contentful?.bundleReference;
  const isBundle = !!(productBundleReference && productBundleReference.length);
  const isInWishlist = product && wishlistContext?.wishlistItems?.includes(product.handle);

  const saleBlocks = data.allContentfulProductPageSaleBlock?.edges;
  let saleBlockData = null;
  if (saleBlocks) {
    saleBlockData = saleBlocks.find((saleBlock) => saleBlock.node.title !== "null")?.node;
  }
  const saleBlock = {
    title: saleBlockData?.title,
    subtitle: saleBlockData?.subtitle,
    content: saleBlockData?.content,
    backgroundColors: saleBlockData?.backgroundColors,
    textColors: saleBlockData?.textColor,
  };

  const isExcludedProduct = (product?.tags || []).some((r) => exclusionTags.includes(r));

  // When productVariants load, check if the current product variant
  // is available for sale and set it with setVariantSelectedOptions
  useEffect(() => {
    if (!userChanged && productVariantsInventory) {
      for (let i = 0; i < productVariantsInventory.length; i++) {
        const v = productVariantsInventory[i];
        if (v.availableForSale) {
          const eqVariant = product.variants.find((e) => e.id === v.id);
          if (eqVariant) {
            const isCurrentSelectedOption = eqVariant.selectedOptions.every(
              ({ name, value }) => variantSelectedOptions[name] === value
            );
            if (!isCurrentSelectedOption) {
              setVariantSelectedOptions(getVariantOptions(eqVariant));
            }
            break;
          }
        }
      }
    }
  }, [product, productVariantsInventory, userChanged, variantSelectedOptions]);

  // Find the variant using the title. This logic is adapted from the original code.
  // The better is to use variant id instead, and we'd need to store the id in the "variant" state.
  const selectedVariant =
    variantSelectedOptions &&
    product?.variants?.find(
      (x) => x.title === variantSelectedOptions[Object.keys(variantSelectedOptions)[0]]
    );

  const selectedVariantInventory = productVariantsInventory.find(
    (v) => v?.id === selectedVariant?.id
  );
  if (selectedVariantInventory) {
    // Set recent availability from check-inventory
    selectedVariant.availableForSale = selectedVariantInventory.availableForSale;
  }

  const priceInfo = usePriceLocalInfo({
    handle: product.handle,
    price: productPrice,
    selectedVariant,
    tags: { [product.handle]: product?.tags },
  });

  const [{ data: fetchedPrices, isInitialLoading: pricesLoading }] = usePriceInfo({
    handles: [product.handle],
    tags: { [product.handle]: product?.tags },
    shouldFetchPrice: !priceInfo,
  });

  const prices = useMemo(() => fetchedPrices || priceInfo, [priceInfo, fetchedPrices]);

  useEffect(() => {
    const trackProductData = {
      price: prices?.final_price,
      title: product.title,
      productType: product.productType,
      productId: product.shopifyId.replace("gid://shopify/Product/", ""),
      product,
    };
    trackViewProductPage(trackProductData);
  }, []);

  const galleryImages = useMemo(
    () => getGalleryImages(_product?.node, variantSelectedOptions),
    [_product, variantSelectedOptions]
  );

  const handleOptionChange = (event) => {
    const { target } = event;
    setUserChanged(true);
    setVariantSelectedOptions((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const toggleDropHintPopup = () => {
    if (isBrowser) {
      if (!showDropHintPopup) {
        trackDropHintOpenPopup({ pageUrl: window.location.href });
      }
      if (showDropHintPopup) {
        trackDropHintClosePopup({ pageUrl: window.location.href });
      }
    }

    setShowDropHintPopup(!showDropHintPopup);
  };

  const loadingMobile = (
    <div className="pdp_sk_m">
      <div className="pdp_sk_image">
        <ALSkeleton />
      </div>
      <div className="pdp_sk_content">
        <div className="pdp_sk_content_name">
          <ALSkeleton />
        </div>
        <div className="pdp_sk_content_price">
          <ALSkeleton />
        </div>
      </div>
      <div className="pdp_sk_content_reviews">
        <ALSkeleton />
      </div>
      <div className="pdp_sk_content_atc">
        <ALSkeleton />
      </div>
    </div>
  );

  const loadingDesktop = (
    <div className="pdp_sk_d">
      <div className="pdp_sk_breadcrumb">
        <ALSkeleton />
      </div>
      <div className="pdp_sk_content">
        <div className="pdp_sk_images">
          <div className="pdp_sk_image">
            <div className="pdp_sk_image_c">
              <ALSkeleton />
            </div>
          </div>
          <div className="pdp_sk_image">
            <div className="pdp_sk_image_c">
              <ALSkeleton />
            </div>
          </div>
        </div>
        <div className="pdp_sk_details">
          <div className="pdp_sk_details_name">
            <ALSkeleton />
          </div>
          <div className="pdp_sk_details_price">
            <ALSkeleton />
          </div>
          <div className="pdp_sk_details_reviews">
            <ALSkeleton />
          </div>
          <div className="pdp_sk_details_atc">
            <ALSkeleton />
          </div>
          <div className="pdp_sk_details_wishlist">
            <ALSkeleton />
          </div>
          <div className="pdp_sk_details_shipping">
            <ALSkeleton />
          </div>
          <div className="pdp_sk_details_delivery">
            <ALSkeleton />
          </div>
          <div className="pdp_sk_details_usp">
            <ALSkeleton />
          </div>
        </div>
      </div>
    </div>
  );

  const loadingBlocks = useResponsiveWithHydrationFix(loadingMobile, loadingDesktop);

  const productJsonLd = useMemo(() => {
    try {
      if (isBrowser && _product?.contentful) {
        let category = "Jewelry";
        if (_product.contentful.type?.[0]) {
          category += ` > ${_product.contentful.type[0]}`;
        }
        if (_product.contentful.subtype?.[0]) {
          category += ` > ${_product.contentful.subtype[0]}`;
        }

        const productForSeo = {
          title: _product.contentful.title,
          description:
            _product.contentful.seoContent?.description?.description ||
            _product.contentful.description?.description,
          images: _product.node.images,
          variants: product.variants,
          handle: product.handle,
          isPreOrder: _product.contentful.isPreOrder,
          productDetails: _product.contentful.productDetails,
          category,
          prices,
          alternatives: {
            hasAlternative: data.alternativesProducts.edges.length > 0,
            isLoadingPrices: alternativesPrices.length > 0 && alternativesPrices[0].isLoading,
            title: pageContext?.alternativeTitle,
            shopifyProducts: edgesToArray(data.alternativesProducts),
            contentfulProducts: _product?.contentful?.productAlternatives?.alternatives || [],
            prices: alternativesPrices || null,
          },
        };

        let structuredData = null;
        if (
          productForSeo.alternatives.hasAlternative &&
          !productForSeo.alternatives.isLoadingPrices
        ) {
          structuredData = buildSEOProductGroupObject(productForSeo);
        } else if (!productForSeo.alternatives.isLoadingPrices) {
          structuredData = buildSEOProductObject(productForSeo);
        }
        console.log(structuredData, " => structuredData");
        return structuredData;
      }
    } catch (error) {
      throwReport(error);
    }
    return null;
  }, [_product, product, data, prices, throwReport]);

  const toggleWishlist = useCallback(
    (action) => {
      if (action === ActionType.ADD) {
        gaCustomEvent(`${product.title} - Add to Wishlist`, "Click");
        wishlistContext.addItemToWishlist({
          type: product.productType,
          productHandle: product.handle,
          productName: product.title,
          productId: product.shopifyId,
          price: product.variants[0].price,
        });
      }

      if (action === ActionType.REMOVE) {
        gaCustomEvent(`${product.title} - Remove from Wishlist`, "Click");
        wishlistContext.removeItemFromWishlist({
          productHandle: product.handle,
          productId: product.shopifyId,
          price: product.variants[0].price,
        });
      }
    },
    [
      currency,
      product.handle,
      product.productType,
      product.shopifyId,
      product.title,
      product.variants,
      wishlistContext,
    ]
  );

  const preOrderData =
    data.preOrder.edges.length > 0
      ? data.preOrder.edges.filter((p) => p.node.handle === _product.node.handle)[0].node
      : null;

  const fullProduct = useMemo(() => {
    if (productVariantsInventory && productVariantsInventory.length > 0) {
      _product.node.variants = _product.node.variants.map((v) => {
        const variantInventory = productVariantsInventory.find((p) => p.id === v.shopifyId);
        v.availableForSale = variantInventory?.availableForSale;
        return v;
      });
    }
    return {
      ..._product.contentful,
      ..._product.node,
      preOrder: preOrderData,
      reviews: data.reviews,
    };
  }, [_product, preOrderData, data.reviews, productVariantsInventory]);

  const scrollToReviews = useCallback(() => {
    if (isBrowser && refReviewContainer.current) {
      const diffPixel = isMobile ? 65 : 100;
      const y =
        refReviewContainer.current.getBoundingClientRect().top + window.pageYOffset - diffPixel;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [isMobile]);

  const scrollToVariants = () => {
    if (variantsSelectorRef?.current) {
      window.scrollTo({ behavior: "smooth", top: variantsSelectorRef.current.offsetTop });
    }
  };

  const isUS = countryCode === Country.US;
  const isGiftCard = _product?.node?.handle === HandleType.GIFT_CARD;

  const seoProductDescription = _product?.contentful?.seoContent?.description?.description;
  const productDescription = _product?.contentful?.description?.description || "";

  const showFloatingATC = !(!isMobile || (!isUS && isGiftCard));

  if (!_product?.contentful) {
    return loadingBlocks;
  }

  const productImages = _product?.node?.images;
  let productFirstImage = null;
  let ogImage = null;
  if (productImages.length > 0) {
    productFirstImage = productImages[0].url;
    const PARAMS = "1080x567_crop_center";
    ogImage = `${productFirstImage.split(".jpg")[0]}_${PARAMS}.jpg`;
  }

  const getInspiredImages = _product.contentful.getInspired?.[0].images;

  // Do not display engravable products to customers placing a replacement order
  if (isLoopReturnEnabled && fullProduct.engravable?.isEngravable) {
    navigate("/404");
  }

  return (
    <div className="pdp_wrapper pdp_wrapper--variant">
      <SEO
        title={
          _product?.contentful?.seoContent?.metaTitle
            ? _product?.contentful?.seoContent?.metaTitle
            : _product.contentful.title
        }
        description={seoProductDescription || productDescription}
      />
      {/* SEO Product Microdata */}
      <Helmet>
        <meta itemProp="name" content={`${_product.contentful.title} | Ana Luisa Jewelry`} />
        {productFirstImage && <link itemProp="image" href={productImages[0].url} />}
        {ogImage && <meta property="og:image" content={ogImage} />}
        <link rel="canonical" href={`https://www.analuisa.com/products/${_product.node.handle}/`} />
        <meta
          property="og:url"
          content={`https://www.analuisa.com/products/${_product.node.handle}/`}
        />
        <div itemProp="brand" itemType="https://schema.org/Brand" itemScope>
          <meta itemProp="name" content="Ana Luisa" />
        </div>
        {productJsonLd && (
          <script type="application/ld+json">{JSON.stringify(productJsonLd)}</script>
        )}
      </Helmet>

      <AggregateOfferMeta product={product} currency={currency} />

      {!isMobile && (
        <div className="product_breadcrumb">
          <Breadcrumb
            productTitle={_product.contentful.title || null}
            collectionTitle={pageContext.collectionTitle || null}
            productType={_product.contentful.type[0] || null}
            productSubtype={_product.contentful.subtype[0] || null}
          />
        </div>
      )}

      <div className="container_products">
        <Gallery
          images={galleryImages}
          videoId={_product.contentful?.videoId || null}
          isUS={isUS}
          isGiftCard={isGiftCard}
          available={selectedVariant?.availableForSale}
          isInWishlist={isInWishlist}
          toggleWishlist={toggleWishlist}
          onDropHintClick={toggleDropHintPopup}
          componentType={ComponentType.PDP}
        />

        <ALTag
          key={isHydrationComplete ? "al-tag-client" : "al-tag-server"}
          product={{ contentful: { node: _product?.contentful }, node: product }}
          componentType={ComponentType.PDP}
          priceInfo={prices}
          shouldDisplayPreOrder={selectedVariant?.inventoryPolicy === InventoryPolicies.CONTINUE}
        />

        <div
          key={isHydrationComplete ? "product-section-client" : "product-section-server"}
          className="container_products_details pdp_product_details"
          style={topPosition}
        >
          <ProductSummary
            product={fullProduct}
            countryCode={countryCode}
            selectedVariant={selectedVariant}
            variantSelectedOptions={variantSelectedOptions}
            componentType={ComponentType.PDP}
            onReviewsClick={scrollToReviews}
            onVariantOptionChange={handleOptionChange}
            searchObject={location?.state?.searchObject}
            variantsSelectorRef={variantsSelectorRef}
            prices={prices}
            pricesLoading={pricesLoading}
            optionalProducts={optionalProducts}
            setOptionalProducts={setOptionalProducts}
          />
          {!isExcludedProduct && saleBlockData && saleBlockData !== null && (
            <SaleBlock
              title={saleBlock.title}
              subtitle={saleBlock.subtitle}
              content={saleBlock.content}
              backgroundColors={saleBlock.backgroundColors}
              textColors={saleBlock.textColors}
            />
          )}

          <ProductDetails
            tags={product?.tags}
            bestReviews={data?.reviews?.bestReviews}
            productHandle={_product.node.handle}
            isBundle={isBundle}
            countryCode={countryCode}
            details={_product.contentful.productDetails}
            description={productDescription}
            completeTheLookProducts={completeTheLookProducts}
            title={_product.contentful.title}
            componentType={ComponentType.PDP}
            onScrollToReviews={scrollToReviews}
            isPreOrder={
              _product.contentful.isPreOrder &&
              selectedVariant?.inventoryPolicy === InventoryPolicies.CONTINUE
            }
          />
        </div>
      </div>
      {showFloatingATC && (
        <FloatingAddToCart
          product={fullProduct}
          selectedVariant={selectedVariant}
          variantSelectedOptions={variantSelectedOptions}
          scrollToVariants={scrollToVariants}
          countryCode={countryCode}
          searchObject={location?.state?.searchObject}
          prices={prices}
          optionalProducts={optionalProducts}
          setOptionalProducts={setOptionalProducts}
          optionalProductsType={_product.contentful.optionalProducts?.optionalProductsType}
        />
      )}

      {data.allContentfulLandingPage?.edges[0]?.node && (
        <div className="product_page__blocks">
          <ALCustomLandingPage
            data={data.allContentfulLandingPage.edges[0].node}
            componentType={ComponentType.PDP}
          />
        </div>
      )}

      {/* GET INSPIRED */}
      {getInspiredImages && getInspiredImages?.[0]?.altText !== "empty" && (
        <LazyLoad offset={100} height={500}>
          <GetInspired images={getInspiredImages} />
        </LazyLoad>
      )}

      <Reviews
        ref={refReviewContainer}
        productId={
          isBrowser ? data.shopifyProduct.shopifyId.replace("gid://shopify/Product/", "") : null
        }
      />

      <LazyLoad offset={100} height={500}>
        <DisplayRecommendedProducts data={data} />
      </LazyLoad>

      {showDropHintPopup && <DropHintPopup onClose={toggleDropHintPopup} />}

      <ProductsInCollection collections={edgesToArray(data?.productInCollections) || []}>
        {isMobile && (
          <div className="product_breadcrumb">
            <Breadcrumb
              productTitle={_product.contentful.title || null}
              collectionTitle={pageContext.collectionTitle || null}
              productType={_product.contentful.type[0] || null}
              productSubtype={_product.contentful.subtype[0] || null}
            />
          </div>
        )}
      </ProductsInCollection>
    </div>
  );
}

export default withALErrorBoundary({
  name: "ProductPage",
  priority: "P1",
})(ProductPage);

export const query = graphql`
  query ($id: String!, $shopifyStore: String!, $lang: String!, $alternatives: [String]!) {
    allShopifyPrices(filter: { handle: { eq: $id } }) {
      edges {
        node {
          id
          country
          shopifyId
          handle
          variants {
            id
            priceV2: price {
              amount
              currencyCode
            }
            compareAtPriceV2: compareAtPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    shopifyProduct(handle: { eq: $id }, storeName: { eq: $shopifyStore }) {
      id
      title
      handle
      createdAt(fromNow: true)
      publishedAt
      productType
      tags
      shopifyId
      variants {
        availableForSale
        inventoryPolicy
        id
        title
        sku
        shopifyId
        selectedOptions {
          name
          value
        }
        image {
          url
          altText
        }
      }
      options {
        id
        name
        values
      }
      images {
        url
        altText
      }
    }
    reviews: contentfulProductPageReviews(handle: { eq: $id }) {
      id
      handle
      bestReviews {
        id
        name
        stars
        content {
          content
        }
      }
    }
    preOrder: allContentfulProductsPreOrderProduct(
      filter: { handle: { eq: $id }, node_locale: { eq: $lang } }
    ) {
      edges {
        node {
          handle
          shippingDate
          startDate
          endDate
        }
      }
    }
    allContentfulProductPage(filter: { handle: { eq: $id }, node_locale: { eq: $lang } }) {
      edges {
        node {
          id
          node_locale
          handle
          hts
          isPreOrder
          preOrderShippingDate
          releaseDate
          videoId
          title
          description {
            description
          }
          type
          subtype
          engravable {
            isEngravable
            fonts
            numberOfCharacters
          }
          bundleReference {
            handle
          }
          productDetails {
            components
            caratWeight
            chainWidth
            charmDimensions
            clarity
            clasp
            color
            craftedIn
            height
            hoopDiameter
            innerDiameter
            length
            pendantDimensions
            post
            depth
            weight
            width
            stoneDiameter
            hoopOuterDiameter
            hoopInnerDiameter
            stoneDimension
            adjustableChain
          }
          seoContent {
            type
            metaTitle
            description {
              description
            }
          }
          getInspired {
            images {
              altText
              desktopImage {
                file {
                  url
                }
              }
              mobileImage {
                file {
                  url
                }
              }
            }
          }
          productAlternatives {
            alternatives {
              handle
              attributes
            }
          }
          completeTheLook
          optionalProducts {
            optionalProductsTitle
            optionalProductsCollection {
              handle
            }
            optionalProductsType
            optionalProductsIcon {
              gatsbyImageData
            }
          }
        }
      }
    }
    alternativesProducts: allShopifyProduct(filter: { handle: { in: $alternatives } }) {
      edges {
        node {
          handle
          title
          shopifyId
          variants {
            availableForSale
            shopifyId
            sku
            selectedOptions {
              name
              value
            }
          }
          tags
          images {
            url
            altText
          }
        }
      }
    }
    alternativesPrices: allShopifyPrices(
      filter: {
        handle: { in: $alternatives }
        variants: { elemMatch: { price: { currencyCode: { eq: "USD" } } } }
      }
    ) {
      edges {
        node {
          variants {
            sku
            price {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    allContentfulLandingPage(
      filter: { handle: { eq: "__PRODUCT_PAGE__" }, node_locale: { eq: $lang } }
    ) {
      edges {
        node {
          blocks {
            ...ContentfulLandingPageBlocks
          }
        }
      }
    }
    allContentfulProductPageSaleBlock(filter: { node_locale: { eq: $lang } }) {
      edges {
        node {
          title
          subtitle
          content {
            raw
          }
          backgroundColors {
            desktop
            mobile
          }
          textColor {
            desktop
            mobile
          }
        }
      }
    }
    productInCollections: allShopifyCollection(
      filter: { products: { elemMatch: { handle: { eq: $id } } } }
    ) {
      edges {
        node {
          title
          handle
        }
      }
    }
  }
`;
