import { useContext } from "react";
import PropTypes from "prop-types";

import { LuxeContext } from "../../context/LuxeProvider";
import useDeviceDetect from "../../hooks/useDeviceDetect";

import ALRewardsNavigation from "../ALRewardsNavigation";

function CollectionHeader({ title, imageSrc, altText, seoTitle, collectionHandle, type }) {
  const {
    luxe: { luxeEdit, isLuxeActive },
  } = useContext(LuxeContext);
  const { isMobile } = useDeviceDetect();

  const shouldRenderCollectionImage = ["co-creation", "image"].includes(type);

  const shouldDisplayNavigation =
    isLuxeActive && luxeEdit.title !== "null" && collectionHandle === luxeEdit?.collection?.handle;

  return (
    <>
      {shouldDisplayNavigation && isMobile && <ALRewardsNavigation />}
      {imageSrc && shouldRenderCollectionImage && (
        <div className="container_collection_image">
          <img alt={altText || ""} src={imageSrc} />
          {seoTitle ? (
            <>
              <h1 style={{ display: "none" }}>{seoTitle}</h1>
              <div className="collection_header_title">{title && title}</div>
            </>
          ) : (
            <h1 className="collection_header_title">{title && title}</h1>
          )}
        </div>
      )}
      {shouldDisplayNavigation && !isMobile && <ALRewardsNavigation />}
    </>
  );
}

export default CollectionHeader;

CollectionHeader.propTypes = {
  title: PropTypes.string,
  imageSrc: PropTypes.string,
  altText: PropTypes.string,
  seoTitle: PropTypes.string,
  collectionHandle: PropTypes.string,
  type: PropTypes.string,
};
