import { useContext } from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";

import { LuxeContext } from "../../context/LuxeProvider";
import { ALLink } from "../ALComponents";

import * as Styles from "./theLuxeEditBlock/styles.module.scss";

function TheLuxeEditBlock() {
  const { luxe } = useContext(LuxeContext);
  const { luxeEdit } = luxe;

  const imageData = useResponsiveWithHydrationFix(
    luxeEdit?.image?.mobileImage?.gatsbyImageData,
    luxeEdit?.image?.desktopImage?.gatsbyImageData
  );
  const collectionHandle = luxeEdit.collection?.handle;

  if (luxeEdit.title === "null") {
    return null;
  }

  return (
    <div className={Styles.container}>
      <GatsbyImage
        className={Styles.content_image_container_img}
        image={getImage(imageData)}
        alt={imageData.altText}
        data-testid="the-luxe-edit-image"
      />
      <div className={Styles.buttonContainer}>
        <ALLink
          to={`/collections/${collectionHandle}`}
          className={`button button--large button--primary ${Styles.button}`}
        >
          {luxeEdit.buttonText}
        </ALLink>
      </div>
    </div>
  );
}

export default TheLuxeEditBlock;
