import { useState } from "react";
import PropTypes from "prop-types";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { ALLink as Link } from "../ALComponents";
import IconArrow from "../icons/svgs/arrow.svg";
import "./footerItem/footerItem.scss";

function FooterItem({ footerLink }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const dict = useLocalizedSentenceDict();
  const hasNestedLinks = footerLink?.links && footerLink?.links.length > 0;

  const toggleExpanded = () => {
    setIsExpanded((prevState) => !prevState);
  };

  if (footerLink.title?.toLowerCase() === "empty") {
    return null;
  }

  if (hasNestedLinks) {
    return (
      <li className="footer_collapsible" key={`footer_item_link_${footerLink.title}`}>
        <button
          className={`footerItem_title ${isExpanded ? "expanded" : ""}`}
          onClick={toggleExpanded}
          aria-expanded={isExpanded}
          aria-controls={`submenu_${footerLink.title}`}
          type="button"
        >
          {dict.get(footerLink?.title)} <IconArrow width="13" height="13" />
        </button>
        <ul
          id={`submenu_${footerLink.title}`}
          className={`footer_subitems ${isExpanded ? "expanded" : ""}`}
          role="menu"
        >
          {footerLink.links.map((nestedLink) => (
            <FooterItem key={`footer_subitem_${nestedLink.title}`} footerLink={nestedLink} />
          ))}
        </ul>
      </li>
    );
  }

  const isExternal = footerLink?.link?.link?.startsWith("http");
  return (
    <li className="footerItem_link" key={`footer_item_link_${footerLink.title}`}>
      {isExternal ? (
        <a
          href={footerLink?.link?.link}
          className="footerItem_link"
          title={footerLink?.title}
          rel="noopener noreferrer"
        >
          {dict.get(footerLink?.title)}
        </a>
      ) : (
        <Link className="footerItem_link" to={footerLink?.link?.link} title={footerLink?.title}>
          {dict.get(footerLink?.title)}
        </Link>
      )}
    </li>
  );
}

FooterItem.propTypes = {
  footerLink: PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.shape({
      link: PropTypes.string,
    }),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        link: PropTypes.shape({
          link: PropTypes.string.isRequired,
        }),
      })
    ),
  }).isRequired,
};

export default FooterItem;
