// extracted by mini-css-extract-plugin
export var article_container = "article-module--article_container--d47cc";
export var article_container_content = "article-module--article_container_content--c85d1";
export var article_container_content_lp = "article-module--article_container_content_lp--7bb0f";
export var article_container_content_summary_title = "article-module--article_container_content_summary_title--23be6";
export var article_container_content_table_content = "article-module--article_container_content_table_content--ef876";
export var article_container_content_table_title = "article-module--article_container_content_table_title--558ce";
export var article_container_recommendations = "article-module--article_container_recommendations--a7d22";
export var article_container_recommendations_email = "article-module--article_container_recommendations_email--2334e";
export var article_filter = "article-module--article_filter--6004c";
export var article_filters = "article-module--article_filters--5d5ca";
export var article_filters_container = "article-module--article_filters_container--b059c";
export var article_footer = "article-module--article_footer--40d75";
export var article_footer_articles = "article-module--article_footer_articles--10e20";
export var article_footer_title = "article-module--article_footer_title--4df51";
export var article_header = "article-module--article_header--4666f";
export var article_image = "article-module--article_image--f13b6";
export var article_imageDesktop = "article-module--article_image--desktop--e799e";
export var article_imageMobile = "article-module--article_image--mobile--bd5b6";
export var article_image__gatsby = "article-module--article_image__gatsby--fdc17";