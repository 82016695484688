// extracted by mini-css-extract-plugin
export var article = "articlePreview-module--article--13e7e";
export var articleIsActive = "articlePreview-module--article--isActive--140c7";
export var article_image = "articlePreview-module--article_image--84985";
export var article_imageDesktop = "articlePreview-module--article_image--desktop--2cd7e";
export var article_imageMobile = "articlePreview-module--article_image--mobile--96f62";
export var article_image__gatsby = "articlePreview-module--article_image__gatsby--178b1";
export var article_read = "articlePreview-module--article_read--2b9e3";
export var article_title = "articlePreview-module--article_title--0cf01";
export var awesome = "articlePreview-module--awesome--b65a5";
export var bold = "articlePreview-module--bold--2fba9";
export var center = "articlePreview-module--center--b40e4";
export var h1 = "articlePreview-module--h1--f1c7d";
export var h1Bold = "articlePreview-module--h1--bold--96454";
export var h1Uc = "articlePreview-module--h1--uc--fe5c5";
export var h2 = "articlePreview-module--h2--4b895";
export var h2Bold = "articlePreview-module--h2--bold--e006b";
export var h2Uc = "articlePreview-module--h2--uc--8c849";
export var h3 = "articlePreview-module--h3--0699d";
export var h3Bold = "articlePreview-module--h3--bold--9c882";
export var h3Uc = "articlePreview-module--h3--uc--a884d";
export var h4 = "articlePreview-module--h4--41854";
export var h4Bold = "articlePreview-module--h4--bold--69471";
export var h4Uc = "articlePreview-module--h4--uc--738ee";
export var linkUnderline = "articlePreview-module--link--underline--39d34";
export var microtext = "articlePreview-module--microtext--1ae95";
export var microtextBold = "articlePreview-module--microtext--bold--8f7e9";
export var microtextUc = "articlePreview-module--microtext--uc--d2098";
export var strikethrough = "articlePreview-module--strikethrough--bad2a";
export var strikethroughLg = "articlePreview-module--strikethrough--lg--581ee";
export var strikethroughSm = "articlePreview-module--strikethrough--sm--0b7ef";
export var subtext = "articlePreview-module--subtext--70d42";
export var subtextBold = "articlePreview-module--subtext--bold--f7b91";
export var subtextUc = "articlePreview-module--subtext--uc--25e68";
export var text = "articlePreview-module--text--2f835";
export var textBold = "articlePreview-module--text--bold--923bd";
export var textUc = "articlePreview-module--text--uc--897ca";
export var titleL = "articlePreview-module--title-l--08d24";
export var titleM = "articlePreview-module--title-m--93d10";
export var titleS = "articlePreview-module--title-s--65ffe";
export var titleXl = "articlePreview-module--title-xl--d4972";
export var titleXs = "articlePreview-module--title-xs--54f24";
export var titleXxs = "articlePreview-module--title-xxs--3a12f";
export var uc = "articlePreview-module--uc--5b17e";
export var underline = "articlePreview-module--underline--8e832";